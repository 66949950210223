import ConfiguratorAvailability from "views/ConfiguratorAvailability"
import ConfiguratorLayout from "components/Configurator/Layout"
import { PageProps } from "gatsby"
import React from "react"
import SEO from "components/seo"

type DataProps = {}

const ConfiguratorAvailabilityPage: React.FC<PageProps<DataProps>> = () => (
  <ConfiguratorLayout activePageId="availability">
    <SEO title="Configurator Availability" />
    <ConfiguratorAvailability />
  </ConfiguratorLayout>
)

export default ConfiguratorAvailabilityPage
